import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://8fc28e94c5f610e5135aaca2f21c9d8c@o4507777926692864.ingest.us.sentry.io/4507805866459136",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Sentry.ErrorBoundary
    fallback={
      <div
        style={{
          width: "100vw",
          height: "100vh",
          backgroundColor: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          marginLeft: "30px",
          marginRight: "30px",
          gap: "40px",
          fontFamily: "Lato",
        }}
      >
        <h1
          style={{
            color: "blue",
            fontSize: "50px",
          }}
        >
          White Screen Fix - Update Available
        </h1>

        <span style={{ fontSize: "30px", textAlign: "center" }}>
          If white screen issue persists, update your display by restarting
          device and going to:
        </span>

        <span style={{ fontSize: "30px", textAlign: "center" }}>
          {"Home > Settings (Gear Icon) > GRAY Update Button"}
        </span>

        <span style={{ fontSize: "30px", textAlign: "center" }}>
          Follow on-screen instructions to install update and re-open. <br />
          If already updated, tap RELOAD to resume
        </span>

        <button
          style={{
            fontSize: "30px",
            padding: "10px 20px",
            backgroundColor: "blue",
            color: "white",
            borderRadius: "10px",
          }}
          onClick={() => {
            window.location.reload();
          }}
        >
          RELOAD
        </button>
      </div>
    }
  >
    <App />
  </Sentry.ErrorBoundary>
);
