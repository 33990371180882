export const updateActivationCodeList = [
  "16BST5TzngFqw5lq7",
  "AzqB5PUBZ8kEU3yKE",
  "6olzPUY4p1qg9gnB",
  "6oqIHUVqs70B8fS9",
  "Azyhi3URBeZC5Id8q",
  "169xUyUUsw8dI5u7F",
  "6oqKAUXoqk6n6EJY",
  "16CJQrUXyayg72LsE",
  "AzqRxoUY0e53j5HxX",
  "16CFa6UXvY9ZYEsf9",
  "AzqBwoUVLxoKc3UEO",
];
