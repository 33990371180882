import { useEffect, useRef, useState } from "react";
import connection_socket from "../../config/connection_socket";
import { customAlphabet } from "nanoid";
import axios from "../../config/axios";

const DisplayConnection = ({ setRefresh, started, setStarted, deviceID }) => {
  const device_id = customAlphabet("1234567890", 6)();
  const [success, setSuccess] = useState(false);
  const audioRef = useRef();

  const handleSuccessfulConnection = () => {
    setSuccess(true);
    audioRef.current.volume = localStorage.getItem("volume");
    audioRef.current.play();

    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  useEffect(() => {
    let client_identifier = localStorage.getItem("client_identifier");
    if (!client_identifier) {
      client_identifier = customAlphabet(
        "1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ",
        15
      )();
      localStorage.setItem("client_identifier", client_identifier);
    }

    // start polling for connection status
    const polling = setInterval(() => {
      axios
        .get(`/users/device/connection`, {
          withCredentials: true,
          params: {
            code: device_id,
            client_identifier: client_identifier,
          },
        })
        .then((response) => {
          console.log(response.data.connection.connected);
          if (response.data.connection.connected) {
            clearInterval(polling);
            localStorage.setItem(
              "device_data",
              JSON.stringify(response.data.device)
            );
            handleSuccessfulConnection();
          }
        });
    }, 2500);

    //emitting an event to the server with a unique id to identify the display
    connection_socket.emit("display_send_init_connection", {
      device_id: device_id,
      client_identifier: client_identifier,
    });

    //listening for an event from the server which is
    // sent when the right code is entered in the remote application
    connection_socket.on("display_listen_device_connection", (data) => {
      // console.log(data);
      // localStorage.setItem("device_data", JSON.stringify(data));
      // setRefresh(Math.random());
      //   handleSuccessfulConnection();
    });

    return () => {
      clearInterval(polling);
    };
  }, []);

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        width: "100vw",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "20px",
        position: "relative",
      }}
    >
      <audio
        src="https://wait-signs-static.s3.amazonaws.com/connection-chime.mp3"
        ref={audioRef}
      ></audio>
      {!deviceID && (
        <img
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            objectFit: "cover",
            zIndex: "-1",
            opacity: success ? "0" : "1",
            transition: "opacity 2s",
          }}
          src="/static/connection-1.png"
          alt=""
        />
      )}

      <img
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          objectFit: "cover",
          zIndex: "0",
          opacity: success ? "1" : "0",
          transition: "opacity 2s",
        }}
        src="/static/connection-2.png"
        alt=""
      />
      <img
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          objectFit: "cover",
          zIndex: "2",
          opacity: success ? "1" : "0",
        }}
        src="/static/connection-bg.png"
        alt=""
      />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "4px",
          position: "absolute",
          left: "51.5%",
          top: "39%",
          zIndex: 3,
          opacity: success ? "0" : "1",
        }}
        // onClick={handleSuccessfulConnection}
      >
        {/*<span style={{ fontSize: '32px', fontWeight: '600', textTransform: 'uppercase', color: '#ffffff', }}>Your Device ID</span>*/}
        <span
          style={{
            fontSize: "70px",
            fontWeight: "800",
            letterSpacing: "wider",
            color: "#012f51",
            fontFamily: "Lato",
            transform: "rotate(14deg)",
          }}
        >
          {" "}
          {device_id}{" "}
        </span>
      </div>
    </div>
  );
};

export default DisplayConnection;
