import React, { useEffect, useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import axios from "../../config/axios";
import live_display_socket from "../../config/live_display_socket";
import moment from "moment-timezone";
import { updateActivationCodeList } from "../../config/updateConfig";

const Settings = ({ device, setShowSettings }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const [view, setView] = useState("all");
  const [volume, setVolume] = useState(
    localStorage.getItem("volume")
      ? parseFloat(localStorage.getItem("volume")) * 10
      : 10
  );
  const [showPassInput, setShowPassInput] = useState(false);
  const [passcode, setPasscode] = useState("");

  const updateDeviceView = (view) => {
    axios
      .patch(
        `/users/device/${device._id}`,
        { screen_view: view },
        { withCredentials: true }
      )
      .then(() => {
        live_display_socket.emit("remote_refresh", {
          device_id: device?._id,
        });
      })
      .catch((error) => {});
  };

  const goToWifiSettingsPageOnExpoApp = () => {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        action: "open-wifi-settings",
      })
    );
  };

  const handleDeviceScrollIntervalUpdate = (value) => {
    axios
      .patch(
        `/users/device/${device._id}`,
        { auto_scroll_interval: value },
        { withCredentials: true }
      )
      .then(() => {
        live_display_socket.emit("remote_refresh", {
          device_id: device?._id,
        });
      })
      .catch((error) => {});
  };

  const updateTimezone = (timezone) => {
    axios
      .patch(
        `/users/device/${device._id}`,
        { timezone: timezone },
        { withCredentials: true }
      )
      .then(() => {
        setShowSettings(false);
        live_display_socket.emit("remote_refresh", {
          device_id: device?._id,
        });
      })
      .catch((error) => {
        // console.log(error)
      });
  };

  const updateDevice = (payload) => {
    axios
      .patch(`/users/device/${device._id}`, payload, { withCredentials: true })
      .then(() => {
        live_display_socket.emit("remote_refresh", {
          device_id: device?._id,
        });
      })
      .catch((error) => {
        // console.log(error)
      });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        top: "0%",
        backgroundColor: "white",
        width: "100vw",
        height: "100vh",
        zIndex: 50,
        opacity: "90%",
      }}
    >
      <div
        style={{ position: "absolute", top: "5%", right: "3%" }}
        onClick={() => setShowSettings(false)}
      >
        <IoCloseSharp size={60} />
      </div>

      {view === "AutoScroll" ? (
        <>
          <div
            style={{
              display: "flex",
              width: "80vw",
              flexWrap: "wrap",
              gap: "10px",
            }}
          >
            {[...Array(12)].map((_, i) => {
              const interval = (i + 1) * 5;
              return (
                <button
                  onClick={() => {
                    handleDeviceScrollIntervalUpdate(interval);
                    updateDeviceView("AutoScroll");
                  }}
                  style={{
                    marginLeft: "10px",
                    border: "2px solid blue",
                    background: "transparent",
                    color: "blue",
                    fontFamily: "Lato",
                    fontSize: "35px",
                    padding: "0px 20px",
                    borderRadius: "20px",
                    fontWeight: "700",
                    margin: "10px 0px",
                    cursor: "pointer",
                  }}
                >
                  {interval} seconds
                </button>
              );
            })}
          </div>
        </>
      ) : view === "TimeZone" ? (
        <>
          <div style={{ display: "flex", gap: "10px" }}>
            <button
              onClick={() => {
                updateTimezone("EST-Eastern Daylight Time,(US/Canada)");
              }}
              style={{
                marginLeft: "10px",
                border: "2px solid blue",
                background: "transparent",
                color: "blue",
                fontFamily: "Lato",
                fontSize: "35px",
                padding: "0px 20px",
                borderRadius: "20px",
                fontWeight: "700",
                margin: "10px 0px",
                cursor: "pointer",
              }}
            >
              <span>Orlando, Florida</span>
            </button>
            <button
              onClick={() => {
                updateTimezone("PST-Pacific Standard Time,(US/Canada)");
              }}
              style={{
                marginLeft: "10px",
                border: "2px solid blue",
                background: "transparent",
                color: "blue",
                fontFamily: "Lato",
                fontSize: "35px",
                padding: "0px 20px",
                borderRadius: "20px",
                fontWeight: "700",
                margin: "10px 0px",
                cursor: "pointer",
              }}
            >
              <span>Anaheim, California</span>
            </button>
            <button
              onClick={() => {
                updateTimezone("my timezone");
              }}
              style={{
                marginLeft: "10px",
                border: "2px solid blue",
                background: "transparent",
                color: "blue",
                fontFamily: "Lato",
                fontSize: "35px",
                padding: "0px 20px",
                borderRadius: "20px",
                fontWeight: "700",
                margin: "10px 0px",
                cursor: "pointer",
              }}
            >
              <span>My Time Zone</span>
            </button>
          </div>
        </>
      ) : view === "Volume" ? (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <input
              className={"range"}
              type="range"
              min="0"
              max="10"
              style={{ width: "500px" }}
              onChange={(e) => {
                let value = e.target.value;
                setVolume(value);
                value = value / 10;
                localStorage.setItem("volume", value);
              }}
              value={volume}
            />
            <button
              style={{
                marginLeft: "10px",
                background: "transparent",
                border: "none",
                color: "blue",
                fontFamily: "Lato",
                fontSize: "70px",
                padding: "0px 20px",
                borderRadius: "20px",
                fontWeight: "700",
                margin: "10px 0px",
                cursor: "pointer",
                marginTop: "40px",
              }}
            >
              {volume}
            </button>
            <div
              style={{
                display: "flex",
                gap: "10px",
              }}
            >
              <button
                style={{
                  marginLeft: "10px",
                  background: "transparent",
                  border: "2px solid blue",
                  color: "blue",
                  fontFamily: "Lato",
                  fontSize: "50px",
                  padding: "0px 20px",
                  borderRadius: "20px",
                  fontWeight: "700",
                  margin: "10px 0px",
                  cursor: "pointer",
                  marginTop: "40px",
                }}
                onClick={() => {
                  if (volume == 0) {
                    setVolume(3);
                    localStorage.setItem("volume", 0.3);
                  } else {
                    setVolume(0);
                    localStorage.setItem("volume", 0);
                  }
                }}
              >
                {parseInt(volume) === 0 ? "Unmute" : "Mute"}
              </button>
              <button
                style={{
                  marginLeft: "10px",
                  background: "transparent",
                  border: "2px solid blue",
                  color: "blue",
                  fontFamily: "Lato",
                  fontSize: "50px",
                  padding: "0px 20px",
                  borderRadius: "20px",
                  fontWeight: "700",
                  margin: "10px 0px",
                  cursor: "pointer",
                  marginTop: "40px",
                }}
                onClick={() => {
                  if (volume != 0) {
                    setVolume(volume - 1);
                    localStorage.setItem("volume", (volume - 1) / 10);
                  }
                }}
              >
                -
              </button>
              <button
                style={{
                  marginLeft: "10px",
                  background: "transparent",
                  border: "2px solid blue",
                  color: "blue",
                  fontFamily: "Lato",
                  fontSize: "50px",
                  padding: "0px 20px",
                  borderRadius: "20px",
                  fontWeight: "700",
                  margin: "10px 0px",
                  cursor: "pointer",
                  marginTop: "40px",
                }}
                onClick={() => {
                  if (volume != 10) {
                    setVolume(volume + 1);
                    localStorage.setItem("volume", (volume + 1) / 10);
                  }
                }}
              >
                +
              </button>
            </div>
          </div>
        </>
      ) : view === "TRC" ? (
        <div>
          <div>
            <span
              style={{
                fontSize: "45px",
                color: "blue",
                fontFamily: "Lato",
              }}
            >
              Announcement:{" "}
            </span>
            <input
              style={{
                width: "35px",
                height: "35px",
              }}
              type="checkbox"
              defaultChecked={device?.announcement ?? true}
              onChange={(e) => {
                updateDevice({ announcement: e.target.checked });
              }}
            />
          </div>
          <div
            style={{
              marginTop: 20,
            }}
          >
            <span
              style={{
                fontSize: "45px",
                color: "blue",
                fontFamily: "Lato",
              }}
            >
              Music:{" "}
            </span>
            <input
              style={{
                width: "35px",
                height: "35px",
              }}
              type="checkbox"
              defaultChecked={device?.trc_music ?? true}
              onChange={(e) => {
                updateDevice({ trc_music: e.target.checked });
              }}
            />
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <button
                onClick={() => {
                  goToWifiSettingsPageOnExpoApp();
                  setShowSettings(false);
                }}
                style={{
                  marginLeft: "10px",
                  border: "2px solid blue",
                  background: "transparent",
                  color: "blue",
                  fontFamily: "Lato",
                  fontSize: "35px",
                  padding: "0px 20px",
                  borderRadius: "20px",
                  fontWeight: "700",
                  margin: "10px 0px",
                  cursor: "pointer",
                }}
              >
                Wifi Settings
              </button>
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <button
                onClick={() => {
                  setView("AutoScroll");
                }}
                style={{
                  marginLeft: "10px",
                  border: "2px solid blue",
                  background: "transparent",
                  color: "blue",
                  fontFamily: "Lato",
                  fontSize: "35px",
                  padding: "0px 20px",
                  borderRadius: "20px",
                  fontWeight: "700",
                  margin: "10px 0px",
                  cursor: "pointer",
                }}
              >
                Enable Auto Scroll
              </button>
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <button
                onClick={() => {
                  setView("TimeZone");
                }}
                style={{
                  marginLeft: "10px",
                  border: "2px solid blue",
                  background: "transparent",
                  color: "blue",
                  fontFamily: "Lato",
                  fontSize: "35px",
                  padding: "0px 20px",
                  borderRadius: "20px",
                  fontWeight: "700",
                  margin: "10px 0px",
                  cursor: "pointer",
                }}
              >
                Time Zone
              </button>
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <button
                onClick={() => {
                  setView("Volume");
                }}
                style={{
                  marginLeft: "10px",
                  border: "2px solid blue",
                  background: "transparent",
                  color: "blue",
                  fontFamily: "Lato",
                  fontSize: "35px",
                  padding: "0px 20px",
                  borderRadius: "20px",
                  fontWeight: "700",
                  margin: "10px 0px",
                  cursor: "pointer",
                }}
              >
                Volume
              </button>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <button
                onClick={() => {
                  setView("TRC");
                }}
                style={{
                  marginLeft: "10px",
                  border: "2px solid blue",
                  background: "transparent",
                  color: "blue",
                  fontFamily: "Lato",
                  fontSize: "35px",
                  padding: "0px 20px",
                  borderRadius: "20px",
                  fontWeight: "700",
                  margin: "10px 0px",
                  cursor: "pointer",
                }}
              >
                Terrace
              </button>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <button
                onClick={() => {
                  window.ReactNativeWebView.postMessage(
                    JSON.stringify({
                      action: "open-download-update",
                      uri: "https://wait-signs-static.s3.us-east-1.amazonaws.com/build-1735401314723.apk",
                    })
                  );
                }}
                style={{
                  marginLeft: "10px",
                  border:
                    urlParams.get("v") != "13" &&
                    updateActivationCodeList.includes(device.subscription._id)
                      ? "2px solid blue"
                      : "2px solid grey",
                  background: "transparent",
                  color:
                    urlParams.get("v") != "13" &&
                    updateActivationCodeList.includes(device.subscription._id)
                      ? "blue"
                      : "grey",
                  fontFamily: "Lato",
                  fontSize: "35px",
                  padding: "0px 20px",
                  borderRadius: "20px",
                  fontWeight: "700",
                  margin: "10px 0px",
                  cursor: "pointer",
                }}
                disabled={urlParams.get("v") == "13"}
              >
                Update
              </button>
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <button
                onClick={() => {
                  setShowPassInput(true);
                  //open an alert and ask for passcode
                  // if passcod is 1234 then console.log('exit app')
                  // else alert('wrong passcode')
                  // const passcode = prompt('Developer Support Portal: Password Required');
                  // if (passcode === '112233') {
                  //     console.log('exit app');
                  //     window.ReactNativeWebView.postMessage(
                  //         JSON.stringify({
                  //             action: 'exit-app',
                  //         }),
                  //     );
                  // }
                }}
                style={{
                  marginLeft: "10px",
                  border: "2px solid blue",
                  background: "transparent",
                  color: "blue",
                  fontFamily: "Lato",
                  fontSize: "35px",
                  padding: "0px 20px",
                  borderRadius: "20px",
                  fontWeight: "700",
                  margin: "10px 0px",
                  cursor: "pointer",
                }}
              >
                Developer
              </button>
            </div>
          </div>

          <br />
          {/*<span style={{fontWeight: 'bold', fontSize: '20px'}}>Time Zone Settings</span>*/}
        </div>
      )}

      {showPassInput && (
        <div
          style={{
            backgroundColor: "blue",
            width: "100vw",
            height: "100vh",
            position: "absolute",
            top: "0%",
            left: "0%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {/* X button to close the passcode modal */}
          <div
            style={{ position: "absolute", top: "5%", right: "3%" }}
            onClick={() => setShowPassInput(false)} // Close the modal on clicking X
          >
            <IoCloseSharp size={60} color="white" />
          </div>
          <span
            style={{
              fontSize: "30px",
              color: "white",
              fontFamily: "Lato",
            }}
          >
            Developer Support Portal: Password Required
          </span>
          <input
            onChange={(e) => setPasscode(e.target.value)}
            type="password"
            placeholder="Enter Passcode"
            style={{
              width: "300px",
              height: "50px",
              fontSize: "20px",
              padding: "20px",
              borderRadius: "10px",
              border: "1px solid grey",
              marginTop: "20px",
            }}
          />
          <button
            style={{
              width: "300px",
              // height: '50px',
              padding: "20px",
              borderRadius: "10px",
              border: "none",
              marginTop: "20px",
              backgroundColor: "green",
            }}
            onClick={() => {
              if (passcode === "2441138") {
                console.log("exit app");
                window.ReactNativeWebView.postMessage(
                  JSON.stringify({
                    action: "exit-app",
                  })
                );
              }
            }}
          >
            <span
              style={{
                fontSize: "30px",
                color: "white",
                fontFamily: "Lato",
              }}
            >
              Submit
            </span>
          </button>
        </div>
      )}
    </div>
  );
};

export default Settings;
