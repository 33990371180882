import { useEffect, useRef, useState } from "react";

const TrainAnimation = ({ refresh, noAnimation, trainSoundRef }) => {
  const [train, setTrain] = useState(false);
  //   const trainSoundRef = useRef();
  const mp3Files = [
    "https://wait-signs-static.s3.amazonaws.com/door1.mp3",
    "https://wait-signs-static.s3.amazonaws.com/door2.mp3",
    "https://wait-signs-static.s3.amazonaws.com/door3.mp3",
    "https://wait-signs-static.s3.amazonaws.com/door4.mp3",
    "https://wait-signs-static.s3.amazonaws.com/door5.mp3",
    "https://wait-signs-static.s3.amazonaws.com/door6.mp3",
  ];

  const getRandomMp3 = () => {
    return mp3Files[Math.floor(Math.random() * mp3Files.length)];
  };

  useEffect(() => {
    if (noAnimation) {
      return;
    } else {
      setTrain(true);
      setTimeout(() => {
        setTrain(false);
      }, 4000);
    }
  }, [refresh]);

  useEffect(() => {
    if (train && trainSoundRef.current) {
      try {
        console.log("trigger train sound full");
        trainSoundRef.current.volume = localStorage.getItem("volume");
        trainSoundRef.current.src = getRandomMp3(); // Set a random MP3 file
        trainSoundRef.current.play();
      } catch (e) {
        console.log(e);
      }
    }
  }, [train]); // Trigger sound play only when train becomes true

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      {/* <audio id="train-audio" ref={trainSoundRef}></audio> */}
      <div
        style={{
          width: "50vw",
          left: !train ? "-50vw" : "0",
          height: "100vh",
          background: "black",
          position: "absolute",
          top: "0%",
          zIndex: "500",
          transition: "all 3s",
        }}
      >
        <img src="/static/door-left.png" alt="" style={{ width: "100%" }} />
      </div>
      <div
        style={{
          width: "50vw",
          right: !train ? "-50vw" : "0",
          height: "100vh",
          background: "black",
          position: "absolute",
          top: "0%",
          zIndex: "500",
          transition: "all 3s",
        }}
      >
        <img src="/static/door-right.png" alt="" style={{ width: "100%" }} />
      </div>
    </div>
  );
};

export default TrainAnimation;
